import React from 'react';
import { Container, Content } from '../../components/Home/homeStyledComponents';
import MenuBar from '../../components/Home/MenuBar';
import FAQ from '../../components/Home/FAQ';
import FAQBrand from '../../components/Home/FAQBrand';
import SocialNetworks from '../../components/Home/SocialNetworks';
import Footer from '../../components/Home/Footer';
import Layout from '../../layouts/index';

// SEZANE
import {
  mostFrequentQuestions as mostFrequentQuestionsSezane,
  customerCareQuestions as customerCareQuestionsSezane,
  serviceQuestions as serviceQuestionsSezane,
} from '../../components/Home/faq/sezaneQuestions';

// GALERIES_LAFAYETTE
import {
  mostFrequentQuestions as mostFrequentQuestionsGaleriesLafayette,
  customerCareQuestions as customerCareQuestionsGaleriesLafayette,
  serviceQuestions as serviceQuestionsGaleriesLafayette,
} from '../../components/Home/faq/galeriesLafayetteQuestions';

// MADURA
import {
  mostFrequentQuestions as mostFrequentQuestionsMadura,
  customerCareQuestions as customerCareQuestionsMadura,
  serviceQuestions as serviceQuestionsMadura,
} from '../../components/Home/faq/maduraQuestions';

// SESSUN
import {
  mostFrequentQuestions as mostFrequentQuestionsSessun,
  customerCareQuestions as customerCareQuestionsSessun,
  serviceQuestions as serviceQuestionsSessun,
} from '../../components/Home/faq/sessunQuestions';

function renderFAQVersion() {
  switch (process.env.GATSBY_BRAND) {
    case 'SEZANE':
      return (<FAQBrand
        mostFrequentQuestions={mostFrequentQuestionsSezane}
        customerCareQuestions={customerCareQuestionsSezane}
        serviceQuestions={serviceQuestionsSezane}
      />);
    case 'GALERIES_LAFAYETTE':
      return (<FAQBrand
        mostFrequentQuestions={mostFrequentQuestionsGaleriesLafayette}
        customerCareQuestions={customerCareQuestionsGaleriesLafayette}
        serviceQuestions={serviceQuestionsGaleriesLafayette}
      />);
    case 'MADURA':
      return (<FAQBrand
        mostFrequentQuestions={mostFrequentQuestionsMadura}
        customerCareQuestions={customerCareQuestionsMadura}
        serviceQuestions={serviceQuestionsMadura}
      />);
    case 'SESSUN':
      return (<FAQBrand
        mostFrequentQuestions={mostFrequentQuestionsSessun}
        customerCareQuestions={customerCareQuestionsSessun}
        serviceQuestions={serviceQuestionsSessun}
      />);
    default:
      return (
        <React.Fragment>
          <FAQ />
          <SocialNetworks />
        </React.Fragment>
      );
  }
}

class FAQPage extends React.Component {
  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Layout routeSlug="FAQPage">
        <Container>
          <Content>
            <MenuBar />
            {renderFAQVersion()}
            <Footer />
          </Content>
        </Container>
      </Layout>
    );
  }
}

export default FAQPage;
