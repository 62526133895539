import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import OutInSideClick from './OutInSideClick';
import { mobileThresholdPixels, colors } from './StyledComponents';

const ModalContent = styled.div`
  padding: ${props => props.noPadding ? '0px' : '20px 20px 0px'};
  background-color: ${colors.white};
  min-width: 300px;
  ${props => props.limited && 'max-width: 700px'};
  position: relative;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100vw;
  }
`;

const ModalShadow = styled.div`
  display: flex;
  justify-content: ${props => props.justify === 'right' ? 'flex-end' : 'center'};
  align-items: center;
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  z-index: 3;
  background-color: rgba(0,0,0,0.35);
  top: 0;
  left: 0;
  ${props => props.backgroundImage && `background: url(${props.backgroundImage});`}
  background-size: cover;
  background-position: center;

  @media (max-width: ${mobileThresholdPixels}) {
    top: -20px;
    min-height: calc(100vh + 20px);
    justify-content: center;
  }
`;

const Modal = ({ show, children, backgroundImage, closeRequest, justify, noPadding }) => {
  if (!show) {
    return null;
  }
  return (
    <div>
      <ModalShadow backgroundImage={backgroundImage} justify={justify}>
        <OutInSideClick handleClickOutside={() => closeRequest(true)}>
          <ModalContent noPadding={noPadding} limited={justify === 'center'}>{children}</ModalContent>
        </OutInSideClick>
      </ModalShadow>
    </div>
  );
};

Modal.propTypes = {
  show: PropTypes.bool,
  noPadding: PropTypes.bool,
  children: PropTypes.node.isRequired,
  backgroundImage: PropTypes.string,
  justify: PropTypes.string,
  closeRequest: PropTypes.func,
};

Modal.defaultProps = {
  show: false,
  noPadding: false,
  backgroundImage: null,
  justify: 'center',
  closeRequest() {},
};

export default Modal;
